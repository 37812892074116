import React from 'react'
import { useTranslation } from "react-i18next";


const About = () => {
  const { t } = useTranslation();

  return (
    <>
    <div className="margin-container">
      <div className="boxed-container">
        <div className='about-container'>
          <h2 className='page-headline'>{t('about-us.headline')}<span className="headline-end">.</span></h2>
          <p>{t('about-us.text')}</p>
        </div>
      </div>
    </div>
    <div className='image-slideshow'>
      <img src="../images/slideshow/slideshow-1.jpg" alt="Slideshow Bild 1"></img>
      <img src="../images/slideshow/slideshow-2.jpg" alt="Slideshow Bild 2"></img>
      <img src="../images/slideshow/slideshow-3.jpg" alt="Slideshow Bild 3"></img>
      <img src="../images/slideshow/slideshow-4.jpg" alt="Slideshow Bild 4"></img>
    </div>
    </>
  )
}

export default About